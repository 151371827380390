import { useEffect } from 'react';
import './App.css';
//import window from 'https://telegram.org/js/telegram-web-app.js';




const tg = window.Telegram.WebApp;
//console.log('=========\n' + tg + '\n==============');

function App() {
  
  useEffect( () => {
    tg.ready();
  })

  const onClose = () => {
    tg.close();
  }

  return (
    <div className="App">
      work<br/>
      work<br/>
      <button onClick={onClose}>Закрыть</button>
    </div>
  );
}

export default App;
